/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'social-instagram': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.4 0H1.6C.72 0 0 .72 0 1.6v12.8c0 .88.72 1.6 1.6 1.6h12.8c.88 0 1.6-.72 1.6-1.6V1.6c0-.88-.72-1.6-1.6-1.6zM8 4.8c1.76 0 3.2 1.44 3.2 3.2 0 1.76-1.44 3.2-3.2 3.2A3.21 3.21 0 014.8 8c0-1.76 1.44-3.2 3.2-3.2zm-6 9.6c-.24 0-.4-.16-.4-.4V7.2h1.68c-.08.24-.08.56-.08.8 0 2.64 2.16 4.8 4.8 4.8 2.64 0 4.8-2.16 4.8-4.8 0-.24 0-.56-.08-.8h1.68V14c0 .24-.16.4-.4.4H2zM14.4 3.6c0 .24-.16.4-.4.4h-1.6c-.24 0-.4-.16-.4-.4V2c0-.24.16-.4.4-.4H14c.24 0 .4.16.4.4v1.6z" _fill="#B4BAC6"/>'
  }
})
